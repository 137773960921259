import React from "react"
import { useState } from "react"
import styled from "styled-components"
import { Document, Page, pdfjs } from "react-pdf"

import PageContainer from "../../components/PageContainer"
import Author from "../../types/api/Author"
import PageInfo from "./PageInfo"
import { CurrentPage, Pages } from "./types"

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js"

interface Props {
  profile: Author["profile"]
}

const Section = styled.section`
  margin: 3rem 0;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`

const StyledDocument = styled(Document)`
  width: 100%;
`

const StyledPage = styled(Page)`
  & canvas {
    width: 100% !important;
    height: auto !important;
  }
`

export default function ProfileViewer({ profile }: Props) {
  const [pages, setPages] = useState<Pages>(0)
  const [currentPage, setCurrentPage] = useState<CurrentPage>(1)

  const decrementPage = (): void => {
    setCurrentPage(currentPage - 1)
  }

  const incrementPage = (): void => {
    setCurrentPage(currentPage + 1)
  }

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setPages(numPages)
  }

  if (!profile) return null
  return (
    <Section>
      <PageContainer>
        <Content>
          <StyledDocument
            file={profile.localFile.publicURL}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            <StyledPage pageNumber={currentPage} width={1000} />
          </StyledDocument>
          <PageInfo
            incrementPage={incrementPage}
            decrementPage={decrementPage}
            pages={pages}
            currentPage={currentPage}
          />
        </Content>
      </PageContainer>
    </Section>
  )
}
