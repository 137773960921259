import React from "react"
import { DecrementPage, PageInfo } from "../types"
import PageButton from "./PageButton"

import LeftArrow from "./LeftArrow"

interface Props extends PageInfo {
  decrementPage: DecrementPage
}

export default function PrevButton({ decrementPage, currentPage }: Props) {
  return (
    <PageButton onClick={decrementPage} disabled={currentPage === 1}>
      <LeftArrow />
    </PageButton>
  )
}
