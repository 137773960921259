import React from "react"
import styled from "styled-components"
import { CurrentPage, PageInfo } from "../types"

interface Props extends PageInfo {}

const Text = styled.p`
  margin: 0 2rem;
`

export default function PageIndicator({ currentPage, pages }: Props) {
  return (
    <Text>
      {currentPage} / {pages}
    </Text>
  )
}
