import styled from "styled-components"
import { theme } from "../../../constants/theme"

const size = "2.5rem"

const PageButton = styled.button`
  width: ${size};
  height: ${size};
  background-color: ${theme.colors.secondary};
  border: 0;
  color: black;
  border-radius: 0.5rem;
  cursor: pointer;
`

export default PageButton
