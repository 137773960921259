import React from "react"
import { IncrementPage, PageInfo as PageInfoData } from "../types"
import PageButton from "./PageButton"

import RightArrow from "./RightArrow"

interface Props extends PageInfoData {
  incrementPage: IncrementPage
}

export default function NextButton({
  incrementPage,
  pages,
  currentPage,
}: Props) {
  const onLastPage: boolean = pages === currentPage
  return (
    <PageButton onClick={incrementPage} disabled={onLastPage}>
      <RightArrow />
    </PageButton>
  )
}
