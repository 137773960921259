import React from "react"
import styled from "styled-components"

import PrimaryLink from "../components/PrimaryLink"
import { Profile } from "../types/api/Author"

const Container = styled.div`
  justify-content: center;
  display: flex;
`

const Button = styled(PrimaryLink)`
  background-color: green;
`

interface Props {
  profile: Profile
}

const DownloadButton = ({
  profile: {
    localFile: { publicURL },
  },
}: Props) => {
  return (
    <Container>
      <Button href={publicURL} download>
        Download
      </Button>
    </Container>
  )
}

export default DownloadButton
