import React from "react"
import styled from "styled-components"

import PrevButton from "./PrevButton"
import NextButton from "./NextButton"
import {
  DecrementPage,
  IncrementPage,
  PageInfo as PageInfoData,
} from "../types"
import PageIndicator from "./PageIndicator"

interface Props extends PageInfoData {
  incrementPage: IncrementPage
  decrementPage: DecrementPage
}

const Container = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
`

export default function PageInfo({
  incrementPage,
  decrementPage,
  ...other
}: Props) {
  return (
    <Container>
      <PrevButton decrementPage={decrementPage} {...other} />
      <PageIndicator {...other} />
      <NextButton incrementPage={incrementPage} {...other} />
    </Container>
  )
}
