import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Author from "../types/api/Author"
import ProfileViewer from "./ProfileViewer"
import Download from "./Download"

interface Data {
  strapiAuthor: Author
}

interface Props {
  data: Data
}

export default function Blog({
  data: {
    strapiAuthor: {
      name,
      description,
      mainAvatar,
      profileHeaderImage,
      profile,
    },
  },
}: Props) {
  const meta = [
    // Sets the thumbnail as SEO image
    {
      name: "og:image",
      content: mainAvatar.localFile.publicURL,
    },
  ]
  return (
    <Layout logo="/hansogroup-logo-horizontal-light.svg">
      <Seo title={name} meta={meta} description={description} />
      <Hero
        title={name}
        fluid={null}
        image={null}
        backgroundImageData={profileHeaderImage || mainAvatar}
      />
      <ProfileViewer profile={profile} />
      {profile && <Download profile={profile} />}
    </Layout>
  )
}
